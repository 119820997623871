(function() {
    var originalAddClassMethod = jQuery.fn.addClass;
    var originalRemoveClassMethod = jQuery.fn.removeClass;

    jQuery.fn.addClass = function() {
        // execute the original method
        var result = originalAddClassMethod.apply(this, arguments);

        // trigger a custom event
        jQuery(this).trigger('cssClassChanged');

        // return the original result
        return result;
    }

    jQuery.fn.removeClass = function() {
        // execute the original method
        var result = originalRemoveClassMethod.apply(this, arguments);

        // trigger a custom event
        jQuery(this).trigger('cssClassChanged');

        // return the original result
        return result;
    }
})();

$(function() {
    $('.nav .nav__for-mobile').bind('cssClassChanged', function() {
        var className = $(this).attr('class');
        var collapsed = className.indexOf('collapsed') !== -1;

        if (collapsed) {
            $(this).parents('.top-block').removeClass('opened');
            $('body').removeClass('overlaid');
            $('#overlay').removeClass('shown');
        } else {
            $(this).parents('.top-block').addClass('opened');
            $('body').addClass('overlaid');
            $('#overlay').addClass('shown');
        }
    });

    $('.nav .nav-menu__item.dropdown').bind('cssClassChanged', function() {
        var className = $(this).attr('class');
        var opened = className.indexOf('open') !== -1;

        if (opened) {
            $(this).parents('.nav__menu').addClass('hidden2');
        } else {
            $(this).parents('.nav__menu').removeClass('hidden2');
        }
    });

    // animated/not top_menu
    var timeDelta = 2000;
    var openTime = new Date('2000-01-01');
    $('.nav .nav-menu__item').hover(function(e) {
        e.preventDefault();
        if ($(this).find('.dropdown-menu').hasClass('open')) {
            return;
        }
        $(this).addClass('active');
        $(this).find('.dropdown-menu').toggleClass('open');

        if (Date.now() - openTime > timeDelta) {
            $(this).find('.dropdown-menu').addClass('animated');
        }
        openTime = Date.now();

    }, function() {
        $(this).removeClass('active');
        $(this).find('.dropdown-menu').removeClass('open');
        $(this).find('.dropdown-menu').removeClass('animated');

        openTime = Date.now();
    });

    // open/close .top-menu__sign-in-select
    $('.top-menu__sign-in-select .dropdown__header').on('touchend', function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('open');
        return false;
    });
    $(document).on('touchend', function (e) {
        setTimeout(() => {
            $('.top-menu__sign-in-select').removeClass('open');
        }, 10);
    });

    // animate voipline logo on hover
    $('.top-menu-block__logo img').hover(function() {
        $(this).animate({ width: 80 }, 300);
    }, function() {
        $(this).animate({ width: 73 }, 300);
    });

    // faq
    $('.faq-block .faq .question').click(function (e) {
        var $parent = $(this).closest('.faq');
        if ($parent.hasClass('opened')) {
            $parent.find('.answer').slideUp('fast');
        } else {
            $parent.find('.answer').slideDown('fast');
        }

        $parent.toggleClass('opened');
    })

    // flags
    makeFlags();
    makePhoneFlags();

    // zopim
    if (typeof $zopim !== 'undefined') {
        $zopim(function () {
            $zopim.livechat.setLanguage('en');
        });
    }

    // open zopim chat by clicking any chat link
    $(document).on('click', '.chat-link', function (e) {
        e.preventDefault();

        if (typeof $zopim === 'undefined') {
            //  non-prod environment or loading error
            console.info('Zopim chat is disabled');
            return;
        }

        $zopim(function () {
            $zopim.livechat.window.show();
        });
    });
});

function focusInput(parent, inputName) {
    var input = $(parent).find('input[name="' + inputName + '"]');
    var value = input.val();
    input.focus();
    input.val('');
    input.val(value);
}

document.addEventListener("DOMContentLoaded", function() {
    var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-bg"));

    if ("IntersectionObserver" in window) {
        let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    entry.target.classList.add("visible");
                    lazyBackgroundObserver.unobserve(entry.target);
                }
            });
        });

        lazyBackgrounds.forEach(function(lazyBackground) {
            lazyBackgroundObserver.observe(lazyBackground);
        });
    }
});

// flags
function makeFlags() {
    $(".flag").each(function () {
        var $new_bg = $(this).css('background-image').replace('country', $(this).attr('data-country'));
        $(this).css('background-image', $new_bg);
    });
}

function replaceFlag(el, newClass) {
    var currentPath = el.css('background-image').split('/');
    var currentClass = currentPath.at(-1).split('.', 1).at(0);

    currentPath[currentPath.length - 1] = currentPath.at(-1).replace(currentClass, newClass);

    var new_bg = currentPath.join('/');
    el.css('background-image', new_bg);
}

function makePhoneFlags() {
    $(".iti-flag").each(function (index, element) {
        var classes = $(this).attr('class').split(' ', 2);
        var $new_bg = $(this).css('background-image').replace('country', classes[1]??'');
        $(this).css('background-image', $new_bg);
    });
}

// price format
function price(price, precision = 2, char = '$') {
    return char + parseFloat(price).toFixed(precision).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ");
}

$(function () {
    makeDotdotdot = function(height, $items, containerClass, truncate, isClickable) {
        if (typeof truncate === 'undefined') {
            truncate = 'word';
        }
        if (typeof isClickable === 'undefined') {
            isClickable = true;
        }

        var dddOptions = {
            ellipsis: "\u2026 Show more",
            height: height,
            truncate: truncate,
            watch: "window",
            callback: function( isTruncated ) {
                if (isTruncated) {
                    // set .dot-class to parent container
                    $(this).closest(containerClass).addClass('dot');
                }
            }
        };

        $items.each(function(index, el) {
            var dot = new Dotdotdot(el, dddOptions);

            if (isClickable) {
                $(el).click(function (e) {
                    e.preventDefault();

                    var $clickedItem = $(e.target);
                    if ($clickedItem[0].tagName === 'A' && $clickedItem[0].href) {
                        window.location = $clickedItem[0].href;
                        return;
                    }

                    if ($clickedItem.hasClass('ddd-truncated')) {
                        // open full text
                        dot.API.restore();
                        dot.API.watch();
                    } else {
                        // truncate text
                        dot.API.truncate();
                    }
                });
            }
        });
    }
});
